// https://theme-ui.com/theme-spec#color

// import { rgba } from 'polished'

const barney = '#921cc6'
const black = '#000000'
const brownishGrey = '#6e6e6e'
const bubblegum = '#fe6bcb'
const bubblegumPink = '#f89cc6'
const bubblegumPinkTwo = '#f98be7'
const offYellow = '#fcde3a'
const pissYellow = '#e0b91b'
const white = '#ffffff'
const wisteria = '#b86bda'
const softGreen = '#65cb99'

export const colors = {
  // Body foreground color
  text: black,
  // Body background color
  background: white,
  // Primary brand color for links, buttons, etc.
  // primary: '#000',
  // A secondary brand color for alternative styling
  // secondary: '',
  // A contrast color for emphasizing UI
  // accent: '#000',
  // A background color for highlighting text
  // highlight: 'rgba(111, 201, 169, 0.1)',
  //A faint color for backgrounds, borders, and accents that do not require high contrast with the background color
  // mute: '#000',
  // Named
  barney,
  black,
  brownishGrey,
  bubblegum,
  bubblegumPink,
  bubblegumPinkTwo,
  offYellow,
  pissYellow,
  white,
  wisteria,
  softGreen,
}
