import { responsivePx } from '../utils'

export const variants = {
  text: {
    default: {
      m: 0,
    },
    heading1: {
      position: 'relative',
      display: 'block',
      fontSize: responsivePx(56, 207),
      lineHeight: 0.86,
      letterSpacing: '-4.14px',
      pt: '0.2em',
    },
    heading2: {
      position: 'relative',
      textTransform: 'uppercase',
      display: 'block',
      color: 'white',
      fontSize: responsivePx(73, 162),
      lineHeight: `${125 / 162}em`,
      letterSpacing: '-3.24px',
    },
    heading3: {
      position: 'relative',
      textTransform: 'uppercase',
      display: 'block',
      color: 'white',
      fontSize: responsivePx(56, 125),
      lineHeight: `${125 / 125}em`,
      letterSpacing: '-2.5px',
    },
    heading4: {
      position: 'relative',
      textTransform: 'uppercase',
      display: 'block',
      color: 'white',
      fontSize: responsivePx(52, 94),
      lineHeight: 1.05,
      letterSpacing: '-1.88px',
    },
    heading5: {
      position: 'relative',
      display: 'block',
      color: 'white',
      fontSize: responsivePx(44, 81),
      lineHeight: '1em',
      letterSpacing: '-1.62px',
      pt: 2,
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 'calc(50% - 40px)',
        content: `''`,
        display: 'block',
        width: '80px',
        height: '4px',
        bg: 'black',
      },
    },
    heading6: {
      textTransform: 'uppercase',
      position: 'relative',
      display: 'block',
      color: 'white',
      fontSize: responsivePx(74, 81),
      lineHeight: '1em',
      letterSpacing: '-1.62px',
      pt: 2,
    },
    heading6line: {
      position: 'absolute',
      top: 0,
      left: 'calc(50% - 40px)',
      display: 'block',
      width: '80px',
      height: '4px',
      bg: 'black',
    },
    heading7: {
      position: 'relative',
      textTransform: 'uppercase',
      display: 'block',
      fontSize: responsivePx(56, 62),
      lineHeight: 0.95,
      letterSpacing: '-1.26px',
    },
    heading8: {
      position: 'relative',
      textTransform: 'uppercase',
      display: 'block',
      color: 'black',
      fontSize: '23px',
      lineHeight: 1.22,
      letterSpacing: 'normal',
      textAlign: 'center',
      pt: 1,
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 'calc(50% - 30px)',
        content: `''`,
        display: 'block',
        width: '60px',
        height: '2px',
        bg: 'black',
      },
    },
    heading9: {
      position: 'relative',
      display: 'block',
      color: 'white',
      fontSize: responsivePx(74, 81),
      lineHeight: '1em',
      letterSpacing: '-1.62px',
      textTransform: 'uppercase',
    },
    heading10a: {
      position: 'relative',
      display: 'block',
      fontSize: responsivePx(44, 56),
      lineHeight: '1em',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
      pt: 2,
    },
    heading10b: {
      position: 'relative',
      display: 'block',
      color: 'black',
      fontSize: responsivePx(36, 44),
      lineHeight: '1em',
      letterSpacing: '-1.62px',
      textTransform: 'uppercase',
    },
    taglineLoud: {
      fontSize: '54px',
      fontWeight: 'heading',
      lineHeight: 1.19,
      letterSpacing: '-0.54px',
    },
    tagline: {
      fontSize: '54px',
      fontWeight: 'body',
      lineHeight: 1.19,
      letterSpacing: '-1.08px',
    },
    footerLinks: {
      fontSize: '22px',
      fontWeight: 'semibold',
      lineHeight: 1.36,
      letterSpacing: '2.2px',
    },
    textLoud: {
      fontSize: '35px',
      fontWeight: 'semibold',
      lineHeight: 1.36,
      letterSpacing: 'normal',
    },
    textBodyLarge: {
      fontSize: '22px',
      fontWeight: 'body',
      lineHeight: '30px',
      letterSpacing: 'normal',
    },
    textBody: {
      fontSize: '19px',
      fontWeight: 'body',
      lineHeight: 1.58,
      letterSpacing: 'normal',
    },
    textBodySplendid: {
      fontSize: '19px',
      fontWeight: 'body',
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
    textSmall: {
      fontSize: '17px',
      fontWeight: 'body',
      lineHeight: 1.65,
      letterSpacing: 'normal',
    },
    textTiny: {
      fontSize: '14px',
      fontWeight: 'body',
      lineHeight: 1.4,
      letterSpacing: 'normal',
    },
    ul: {
      listStyle: 'none',
      pl: 0,
    },
  },
  layout: {
    container: {},
  },
  icons: {
    default: {},
  },
}
