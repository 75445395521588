// index            0  1   2   3   4   5   6   7   8
const fontSizes = [0, 12, 15, 17, 36]
fontSizes.small = fontSizes[1]
fontSizes.body = fontSizes[2]
fontSizes.heading = fontSizes[3]

const lineHeights = [
  0,
  '12px', // 1
  '16px', // 2
  '20px', // 3
  '24px', // 4
  '28px', // 5
  '32px', // 6
  '40px', // 7
  '48px', // 8
]
lineHeights.body = lineHeights[4]
lineHeights.heading = 'normal'

export const typography = {
  // https://theme-ui.com/theme-spec#typography
  fonts: {
    body: `"Avenir Next LT Pro", Futura, Arial, sans-serif`,
    heading: `"Avenir Next LT Pro", Futura, Arial, sans-serif`,
    monospace: `Menlo, monospace`,
  },
  fontWeights: {
    body: 500,
    heading: 700,
    // thin: 100,
    // extralight: 200,
    // light: 300,
    // normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    // extrabold: 800,
    // ultrabold: 900,
  },
  fontSizes,
  lineHeights,
}
