/**
 * This theme uses `theme-ui` under the hood.
 * @see https://theme-ui.com/
 * @see https://theme-ui.com/gatsby-plugin/
 */

import { breakpoints } from '../theme/breakpoints'
import { colors } from '../theme/colors'
import { shadows } from '../theme/shadows'
import { space } from '../theme/space'
import { styles } from '../theme/styles'
import { typography } from '../theme/typography'
import { variants } from '../theme/variants'

export default {
  breakpoints,
  colors,
  shadows,
  space,
  styles,
  ...typography,
  ...variants,

  // ---------------------------------------------------------------------------
  // Configuration
  // @see https://theme-ui.com/theming#configuration-flags
  // ---------------------------------------------------------------------------
  // Enables CSS custom properties to help mitigate a flash of unstyled content
  // during rehydration
  useCustomProperties: true,
  // Adds styles defined in theme.styles.root to the <body> element along with
  // color and background-color
  useBodyStyles: true,
  // The key used for the top-level color palette in theme.colors
  initialColorModeName: 'default',
  // Initializes the color mode based on the prefers-color-scheme media query
  useColorSchemeMediaQuery: false,
  // Adds a global box-sizing: border-box style
  useBorderBox: true,
  // Persists the color mode in localStorage
  useLocalStorage: false,
}
