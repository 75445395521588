export { linkResolver } from './linkResolver'

export const isLink = (to) => to !== undefined
export const isInternalLink = (to) => /^\/(?!\/)/.test(to)
export const isExternalLink = (to) => !isInternalLink(to)

export const nl2br = function (text = '') {
  return typeof text === 'string' ? text.replace(/\n/g, '<br />') : ''
}

export const shySerializer = (text = '') => {
  return typeof text === 'string' ? text.replace(/­/g, '&shy;') : ''
}

const responsivePxFrom = 375
const responsivePxTo = 1920
export const responsivePx = (min, max) => [
  `${min}px`,
  `calc(${min}px + ${max - min} * (100vw - ${responsivePxFrom}px) / ${
    responsivePxTo - responsivePxFrom
  })`,
  null,
  null,
  `${max}px`,
]
