module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Filme Fürs Volk","short_name":"FFV","start_url":"/de","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/ffv-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"006d787f4eeda07fbb9c7d5d16736a0c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"filmefuersvolk","accessToken":"MC5Yd0xxTWhFQUFDRUFVdnNI.Pnnvv73vv73vv70vcBvvv70KRTLvv718WxTvv73vv71dS2bvv71K77-977-9U13vv73vv73vv73vv71d","defaultLang":"de-de","langs":["de-de","en-us"],"shortenUrlLangs":true,"path":"/preview","previews":true,"pages":[{"type":"Home_page","match":"/:lang","path":"/:lang","component":"/opt/build/repo/src/templates/HomePage.js","langs":["de-de","en-us"]},{"type":"Simple_page","match":"/:lang/:uid","path":"/:lang/:uid","component":"/opt/build/repo/src/templates/SimplePage.js","langs":["de-de","en-us"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-BEKMZV8X6C","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
