export const styles = {
  // BODY STYLES
  root: {
    fontFamily: 'body',
    fontSize: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
    // https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures
    // fontVariantLigatures: `common-ligatures discretionary-ligatures contextual`,
    // fontFeatureSettings: `'ss01' on, 'ss02' on`,
  },

  // MDX
  // Styles for elements rendered in MDX can be added to the theme.styles
  // object. This is the primary API for applying typographic styles in
  // markdown content. Styles within this object have access to other values
  // in the theme object, such as colors, fonts, and space.
  // h1: {
  //   fontSize: 'heading',
  //   fontFamily: 'heading',
  //   fontWeight: 'heading',
  //   mb: 2,
  // },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  p: {
    mb: 2,
  },
  strong: {
    fontWeight: 'bold',
  },
  h3: {
    fontSize: '35px',
    fontWeight: 'bold',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    color: '#000',
    mt: 0,
    mb: 2,
  },
  h4: {
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '30px',
    letterSpacing: 'normal',
    color: '#000',
    mt: 0,
    mb: 2,
  },
}
